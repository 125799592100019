import React from 'react';
import styles from './loader.module.scss';

interface IProps {
  isDark?: boolean;
  hasContainer?: boolean;
  zIndex?: number;
}

const Loader: React.FC<IProps> = ({ isDark = false, hasContainer, zIndex }) => {
  return (
    <div
      className={`${hasContainer ? styles.hasContainer : ''}${
        isDark ? styles.loaderWrapperDark : styles.loaderWrapper
      }`}
      style={zIndex ? { zIndex: zIndex } : {}}
      data-testid="loader"
    >
      <div className={styles.loader} />
    </div>
  );
};

export default Loader;
